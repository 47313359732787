import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout/Layout";
// import BlogPostDetailsTemplate from "../components/BlogPostDetailsTemplate";
// import { HTMLContent } from "../components/Content";
// import ContactMe from "../components/ContactMe";
import { getCurrentLangKey } from "../langfunctions";
import BlogCard from "../components/BlogCard";
import { Box } from "@mui/material";
import SearchBox from "../components/SearchBox";

// markup
const blogLists = (props) => {
  const data = props.data;
  const jsonData = data.articles.edges[0].node.articles;
  const location = props.location;
  const url = location.pathname;
  const { langs, defaultLangKey } = data.site.siteMetadata.languages;
  const langKey = getCurrentLangKey(langs, defaultLangKey, url);
  const languages = data.site.siteMetadata.languages;
  const id = data.markdownRemark.frontmatter.id;
  const description = data.markdownRemark.frontmatter.description;
  const title = data.markdownRemark.frontmatter.title;
  const date = data.markdownRemark.frontmatter.date;
  // const html = data.markdownRemark.html;
  // const tags = data.markdownRemark.frontmatter.tags;

  return (
    <main>
      <Layout
        title={title}
        jsonData={jsonData}
        languages={languages}
        location={location}
        id={id}
        data={data}
        description={description}
      >
        {date}
        <title>{title}</title>
        <Box sx={{ mt: 6 }}>
          {/* <SearchBox /> */}
          <BlogCard
            projects={langKey === "en" ? data.en.edges : data.zh.edges}
          />
        </Box>
      </Layout>
    </main>
  );
};

export default blogLists;

export const blogsQuery = graphql`
  query blogsPageQuery($id: String!) {
    site {
      siteMetadata {
        title
        siteUrl
        description
        languages {
          defaultLangKey
          langs
        }
      }
    }
    articles: allArticlesJson(filter: { title: { eq: "home" } }) {
      edges {
        node {
          articles {
            en
            zh
          }
        }
      }
    }
    en: allMarkdownRemark(
      limit: 30
      filter: {
        frontmatter: { lang: { eq: "en" }, template: { eq: "blogbody" } }
      }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      edges {
        node {
          frontmatter {
            title
            description
            tags
            author
            slug
            lang
            date
          }
          excerpt
        }
      }
    }
    zh: allMarkdownRemark(
      limit: 30
      filter: {
        frontmatter: { lang: { eq: "zh" }, template: { eq: "blogbody" } }
      }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      edges {
        node {
          frontmatter {
            title
            description
            tags
            author
            slug
            lang
            date
          }
          excerpt
        }
      }
    }
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        date
        description
        headers
        id
        lang
        slug
        tags
        template
        title
      }
      html
    }
  }
`;
